import React from 'react';
import Logo from '../img/order-with-ease-logo.svg';

const Nav = () => {

    return (
        <nav className="row">
            <div className="col-md-2">
                <div className="logo-bar">
                    <img src={Logo} alt="logo" />
                </div>
            </div>
            <div className="col-md-10 margin-top-10">
                {/*<ul class="inline no-margin">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Pricing</a></li>
                    <li><a href="#">Contact</a></li>
    </ul>*/}
            </div>
        </nav>
    );
};

export default Nav;
