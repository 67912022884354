import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/style.css';
import './Css/mobile.css';

import Home from './Pages/Home';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Home />} exact />

          {/* <Route path="*" element={<Navigate to="/" />} /> */}

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
