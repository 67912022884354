import React, { useEffect } from "react";

import LoginBG from '../img/login-bg.jpg';
import Community from '../img/community.jpg';
import Logo from '../img/order-with-ease-logo.svg';

import Nav from '../Components/Nav';

const Home = () => {

    return (
        <>
            <div className="alert-bar text-center bg-black">
                <p><span className="highlight"><b>Attention:</b></span> Our website is currently undergoing major development.</p>
            </div>
            <header>
                <div className="flex">
                    <div className="split-60 height-90vh">
                        <div className="container relative">
                            <div className="row">
                                <div className="col-md-12 height-100vh relative">
                                    <div className="extra-gutter relative height-100vh">
                                        <Nav />
                                        <div className="flex align-center height-100">
                                            <div className="text-wrapper">
                                                <h1>White Label</h1>
                                                <h2>Online & Mobile App Ordering System</h2>
                                                <p>Elevate your brand's online presence and boosting takeout orders, ensuring a seamless and efficient experience for both you and your customers.</p>
                                                <ul className="inline">
                                                    <li> <a href="https://calendly.com/orderwithease/1-on-1-demo-orderwithease" target="_blank" className="default-btn yellow-btn">Book a Demo</a></li>
                                                    <li className="margin-left-30"><a href="#" className="black-text">Learn More</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="split-40 height-90vh bg-size-cover" style={{ background: `url('` + LoginBG + `') no-repeat center center` }}>
                        <div className="margin-top-40 extra-gutter text-right">
                            <a href="https://calendly.com/orderwithease/1-on-1-demo-orderwithease" target="_blank" className="default-btn yellow-btn">Book a Demo</a>
                        </div>
                    </div>
                </div>
            </header>
            <section className="standard-padding bg-lp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Built exclusively <span className="highlight underline skew">for restaurants</span> with restaurants.</h3>
                        </div>
                        <div className="col-md-6">
                            <p>Guided by the invaluable input and real-world experiences of our restaurant partners, our roadmap is meticulously crafted to address their evolving needs and preferences. Every feature and enhancement empowers our restaurant community, driving their success forward with innovation and agility.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="standard-padding community">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-7">
                            <h2>Become Apart of Our Community</h2>
                        </div>
                    </div>
                    <div className="row margin-top-40">
                        <div className="col-md-6">
                            <img src={Community} alt="community" />
                        </div>
                        <div className="col-md-6">
                            <div className="flex align-center height-100">
                                <div className="text-wrapper">
                                    <div className="text-block">
                                        <h3><span className="highlight">Lower fees</span>.</h3>
                                        <p>
                                            We're dedicated to reducing your operational costs by offering lower transaction fees, empowering your restaurant to thrive while maximizing your profit margins..</p>
                                    </div>
                                    <div className="text-block">
                                        <h3>Leverage <span className="highlight">your brand</span>, not ours.</h3>
                                        <p>Harness the power of your brand, not ours. Our platform is designed to amplify your distinct identity, making it effortless for you to stand out and thrive in the online landscape.</p>
                                    </div>
                                    <div className="text-block">
                                        <h3><span className="highlight">You</span> are the roadmap.</h3>
                                        <p>
                                            You set the destination, we get you there. With our platform, your feedback shapes the journey. Tell us what you need, and we'll tailor the platform, ensuring it aligns perfectly with your vision and goals for online ordering success.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-black structures standard-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 text-center">
                            <h2>Suitable for <span className="highlight">All</span> Restaurant Structures</h2>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    <div className="row margin-top-50">
                        <div className="col-md-6">
                            <div className="text-block">
                                <h3>1 Location</h3>
                                <p>
                                    For single-location restaurants, our platform offers a streamlined solution for online ordering, making it easy for customers to place orders and for you to manage them effectively.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-block">
                                <h3>Franchise</h3>
                                <p>For franchises, our platform provides a scalable solution for online ordering across multiple locations, offering centralized management tools and robust features to ensure consistency and success across your entire franchise network.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-lp standard-padding cta">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Get Started</h2>
                            <h3>Book a Demo</h3>
                            <a href="https://calendly.com/orderwithease/1-on-1-demo-orderwithease" target="_blank" className="default-btn yellow-btn">Schedule</a>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="standard-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h4>OrderWithEase</h4>
                            <p>Fully custom white label online & mobile app ordering, with no upfront cost!</p>
                            <div className="flex margin-bottom-30">
                                <a href="">
                                    <div className="social-block flex align-center justify-center">
                                        <i className="fa fa-instagram"></i>
                                    </div>
                                </a>
                                <a href="">
                                    <div className="social-block flex align-center justify-center">
                                        <i className="fa fa-facebook"></i>
                                    </div>
                                </a>
                                <a href="">
                                    <div className="social-block flex align-center justify-center">
                                        <i className="fa fa-twitter"></i>
                                    </div>
                                </a>
                                <a href="">
                                    <div className="social-block flex align-center justify-center">
                                        <i className="fa fa-linkedin"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2">
                            <ul className="margin-bottom-30">
                                <li className="title">Quick Links</li>
                                <li><a href="#">Home</a></li>
                                {/*<li><a href="#">Pricing</a></li>
    <li><a href="#">Contact</a></li>*/}
                            </ul>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <ul>
                                <li className="title">Book a Demo</li>
                            </ul>
                            <p>Provide us your email to schedule a time with us. </p>
                            <form>
                                <input placeholder="example@orderwithease.ca" type="email" />
                                <button><i className="fa fa-arrow-right"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Home;
